.carousel {
    width: 100%;
}
  
.tCarousel {
    padding: 2rem;
}
  
.testimonial {
    display: flex;
    flex-direction: column;
    position: relative;
    background: white;
    border: 2px solid #38b6ff;
    border-radius: .5rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: .5rem;
    box-shadow: 0rem 1rem 3rem -50px #000000;
}
  
  .testimonial > img {
    position: absolute;
    width: 3rem;
    top: -1.5rem;
    left: 45%;
    z-index: 1 !important;
}
  
.testimonial > span:nth-of-type(1) {
    align-items: center;
    font-size: 0.8rem;
    letter-spacing: 1px;
    margin-top: 2rem;
}
  
.testimonial > hr {
    height: 1px;
    width: 80%;
    background: rgb(198,198,198);
    border: none;
}
  
.testimonial > span:nth-of-type(2) {
    font-weight: 500;
  }
  
.star{
    color: yellow;
    font-size: 1.5rem;
    border: 1px solid black;
}
  
.stars {
    font-size: 24px;
    justify-content: center;
    margin-top: 1rem;
    display: flex;
    color: yellow;
    width: 100px;
}

.icon{
    width:50px;
}


.statement{
    font-size: 10px;
    color:#000000;
    text-align: left;
    margin-top: 100px;
    margin-bottom: -70px;
}

@media screen and (max-width:640px) {
    .wrapper{
        grid-template-columns: 1fr;
    }
    .wrapper>img{
        width: 80vw;
    }
    .wrapper>:nth-child(3), .wrapper>:nth-child(1)
    {
        text-align: center;
    }
}

@media screen and (max-width:640px) {
    .statement > span:nth-of-type(1){
        font-size:0.6rem;
    }
    .statement{
        text-align: center;
        width: 88%;
        padding-left: 20px;
    }
}

@media screen and (max-width:640px) {
    .deals{
        padding-left: 20px;
        text-align: center;
        width: 88%;
    }
}

