.container{
    text-align: right;
    font-size: 2rem;
    margin-left:55px;
    margin-right: 55px;
}
.text1{
    text-align: left;
    font-size: 1rem;
    padding: 0.5;
}
.middle{
    text-align: center;
    font-size: 2rem;
    padding: 0.5;
}
