.ourvision{
    display: flex;
    align-items: center;
    justify-content: center;
}


.ourvision img {
    width: 90%;
    height: auto;
    margin: 0 auto;
  }
  

.ourprocess{
    display: flex;
    align-items: center;
    justify-content: center;
}


.ourprocess img {
    width: 90%;
    height: auto;
    margin: 0 auto;
  }

@media screen and (max-width: 640px){
  .learn{
    text-align: center;
    width: 80%;
  }
  .ourprocess> img{
    margin:auto;
    width: 100%;
  }
  .ourvision>img{
    margin:auto;
    width: 100%;
  }
  .learn-text{
    font-size: smaller;
  }
}

