.container{
    padding: 1.5rem;
    display: flex;
}
.logo{
    display:flex;
    align-items: center;
    flex: 1;
}


.logo.img{
    width: 2 rem;
    height: 2 rem;
}
.logo>span{
    font-weight: 600;
}


.right{
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.menu{
    display: flex;
    gap: 2rem;
    font-weight: 500;
    list-style: none;
}
.menu>li:hover{
    color: #38b6ff;
    cursor: pointer;

}

.search{
    width: 9rem;
    outline: none;
    height: 15%;
    border-radius: 5px;
    padding: 0.5rem;

}

.cart{
    width: 1.5rem;
    height: 1.5rem;
}

@media screen and (max-width:640px) {
    .container{
        flex-direction: column;
    }
    .container .logo img{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-left: 40px;
    }
    .menu {
        flex-direction: row;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }
    .search{
        font-size: 10px;
    }
}